$primary-color: #ec1b31;
$secondary-color: #f1f1f1;
*,
html {
    padding: 0;
    margin: 0;
    font-family: "Roboto", sans-serif;
}
body {
    overflow-x: hidden;
    max-width: 100vw;
    .MuiTextField-root {
        label {
            padding-left: 10px;
        }
    }
    .MuiInput-inputMultiline, .MuiInputBase-input{
        padding-left: 10px;
    }
}
h1 {
    text-transform: uppercase;
    font-weight: 900;
    margin-bottom: 0;
    font-size: 4em;
    // color: white;
}
h2 {
    font-weight: 600;
    color: $primary-color;
    text-transform: uppercase;
}
h3 {
    margin: 0.2rem 0 1rem 0;
    font-size: 2em;
    text-transform: uppercase;
}
h4 {
    margin: 2rem 0;
    font-size: 1.5em;
    text-transform: uppercase;
}
p {
    // padding: 0 2rem;
    // text-align: center;
    opacity: 0.75;
    @media screen and (max-width: 992px) {
        text-align: left;
    }
}

ul {
    list-style-type: none;
}

footer {
    position: relative;
    color: white;
    background-color: #303030;
    height: 100vh;
    p {
        color: #a1a1a1;
    }
    ul {
        li {
            margin: 4px 0;
            a {
                color: white;
                text-decoration: none;
            }
        }
    }

    a {
        color: white;
        text-decoration: none;
    }

    .submenu-parent {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        .submenu {
            display: flex;
            justify-content: flex-start;
            flex-direction: column;
            align-items: flex-start;
            h4 {
                margin-bottom: 0.5rem;
            }
        }
    }

    @media screen and (max-width: 991px) {
        height: 100%;
        .submenu-parent {
            width: 100%;
            .submenu {
                margin: 25px 0;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                width: 100%;
                padding: 0 !important;
                h4 {
                    margin-bottom: 0.2rem;
                }
            }
        }
    }
    .footer-bottom {
        position: absolute;
        bottom: 33vh;
        display: flex;
        width: 100vw;
        height: 378px;
        justify-content: center;
        bottom: 0;
        @media screen and (max-width: 992px) {
            position: static;
            padding: 50px 0;
            height: unset;
        }
        svg {
            position: absolute;
            z-index: 1;
            width: 100%;
            height: 380px;
            @media screen and (max-width: 992px) {
                display: none;
            }
        }
        .inner {
            position: absolute;
            z-index: 2;
            top: 25vh;
            @media screen and (max-width: 992px) {
                position: static;
            }
        }
    }
}
.hidden {
    display: none;
}
/// SCROLL BAR STYLE
*::-webkit-scrollbar {
    width: 6px;
}
*::-webkit-scrollbar-track {
    background: rgb(255, 255, 255);
}
*::-webkit-scrollbar-thumb {
    background-color: $primary-color;
    border-radius: 0px;
}

#hero {
    min-height: 60vh;
    min-height: 60vh;
    max-height: 100vh;
    overflow: hidden;
    position: relative;
    @media screen and (max-width: 1234px) {
        min-height: unset;
    }
    @media screen and(max-width: 992px) {
        margin-top: 20vh;
    }
}

#about,
#jobs,
#contact {
    position: relative;
    min-height: 85vh;
    min-width: 100vw;
    display: flex;
    flex-direction: column;
    p {
        text-align: left;
        padding: 0;
    }
    @media screen and (max-width: 992px) {
        min-height: unset;
        margin-top: 5em;
    }
}

#detail_page,
#markdown {
  
    @media screen and (max-width: 992px) {
        margin-top: 10em !important;
    }
}

.markdown {
    p {
    opacity: unset;
    }
    @media screen and (max-width: 992px) {
        padding-top: 4em !important;
    }
}

#about {
    margin-top: 10em;
    position: relative;
    .background-el {
        position: absolute;
        left: 5vw;
        width: 70vw;
        top: 10vh;
        opacity: 0.8;
        @media screen and (max-width: 992px) {
            display: none;
        }
    }
}
.background-jobs-front {
    position: absolute;
    z-index: -1;
    left: 0;
    height: 50vh;
    width: 100vw;
    overflow: hidden;
    @media screen and (max-width: 960px) {
        display: none !important;
    }
}

.btn {
    background-color: $primary-color;
    border: 0;
    color: white;
    font-size: 1.3em;
    padding: 0.3em 2em;
    margin: 0.5em 0;
    // width: 1;
    font-weight: 300;
    text-transform: uppercase;
    &:hover {
        cursor: pointer;
        background-color: lighten($primary-color, $amount: 3);
    }
}

header {
    .handshake {
        margin-top: 10%;
        @media screen and (max-width: 900px) {
            display: none;
        }
    }
    .top_bar {
        background-color: $primary-color;
        min-height: 35px;
        .MuiContainer-root {
            min-height: inherit;
            .MuiBox-root {
                min-height: inherit;
            }
        }
        @media screen and (max-width: 992px) {
            display: none;
        }
    }
    .inner {
        .left {
            a {
                margin-right: 3rem;
            }
        }
        .right {
            a {
                margin-left: 1rem;
            }
        }
        ul {
            height: 100%;
            display: flex;
            align-items: center;
            li {
                height: 100%;
                a {
                    display: flex;
                    align-items: center;

                    text-decoration: none;
                    color: white;
                    &:hover {
                        text-decoration: none;
                        color: white;
                    }
                }
            }
        }
    }

    .__nav_bar_ruler {
        height: 100px;
        display: flex;
        width: 100vw;
        justify-content: space-between;
        .__nav_bar {
            background-color: $secondary-color;
            color: white;
            top: -100px;
            display: flex;
            justify-content: space-between;
            width: 100vw;
            z-index: 1;
            .__sub_menu {
                background-color: white;
                position: absolute;
                width: 100%;
                height: 100%;
                left: -60px;
                top: 15px;
                display: flex;
                flex-direction: column;
                li {
                    margin: 0px auto 0px auto;
                    padding: 5px 20px;
                    background-color: white;
                    min-width: 100px;
                    border-bottom: 1px solid #f1f1f1;
                }
            }
            ul {
                display: flex;
                li {
                    list-style-type: none;
                    color: #222222;
                    margin-left: 12px;
                    margin-right: 12px;
                    display: flex;
                    align-items: center;
                    &:hover {
                        cursor: pointer;
                        color: black;
                    }
                    a {
                        display: flex;
                        align-items: center;
                        color: #222222;
                        text-decoration: none;
                        font-weight: 300;
                        &:hover {
                            cursor: pointer;
                            color: black;
                        }
                    }
                }
                > .active {
                    > a {
                        color: black;
                        text-decoration: none;
                        font-weight: 600;
                    }
                }
            }
            .__divider {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-left: auto;
                margin-right: auto;
                padding-left: 16px;
                padding-right: 16px;
                @media screen and (min-width: 600px) {
                    padding-left: 24px;
                    padding-right: 24px;
                }
                @media screen and (min-width: 1280px) {
                    max-width: 1280px;
                }
                svg {
                    @media screen and (max-width: 991px) {
                        display: none;
                    }
                }
            }

            transition: all 0.6s ease-in-out;
        }
        .__nav_bar_fixed {
            @extend .__nav_bar;
            background-color: $secondary-color;
            top: 0;
            position: fixed;
            height: 80px;
            z-index: 99;
            display: flex;

            img {
                max-width: 120px;
            }
        }
        @media screen and (max-width: 991px) {
            display: none;
        }
    }
    .top_mast_slider {
        width: 100%;
        h3 {
            font-weight: "normal";
            padding: 0 5em;
            font-size: 1.5em;
            margin: 0;
            color: white;
            @media screen and (max-width: 992px) {
                font-size: 1em;
                padding: 0 1em;
            }
        }
        .__hero_image {
            position: absolute;
            top: 0;
            width: 100vw;
            height: 100vh;
            z-index: -3;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                position: absolute;
                z-index: -3;
                opacity: 0.9;
            }
        }
        .slick-slider {
            width: 100vw;
            box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.301);
        }
        .slide {
            display: flex;
            flex-direction: column;
            align-items: center;
            align-content: center;
            justify-content: center;
            height: 80vh;
            z-index: 1;
            h1 {
                color: white;
            }

            &:before {
                position: absolute;
                content: "";
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background: rgba(0, 0, 0, 0.1);
                z-index: -2;

                @media screen and (max-width: 961px) {
                    background: rgba(0, 0, 0, 0.1);
                }
            }
            @media screen and (max-width: 1234px) {
                h1 {
                    margin-top: 2vh;
                    font-size: 2em;
                }
            }
            .slick-list {
                width: 100vw;
                z-index: 1;
                @media screen and (max-width: 1234px) {
                    width: unset;
                }
            }
            @media screen and (max-width: 1234px) {
                width: unset;
            }
        }

        .slick-dots {
            z-index: 1;
            bottom: 2vh;
            @media screen and (max-width: 1234px) {
            }
            li {
                width: 18px;
                height: 12px;
                margin-bottom: 10px;
            }

            li button:before {
                border: 1px solid black;
                border-radius: 100%;
                width: 16px;
                height: 16px;
                font-family: unset;
                font-size: 0;
                background-color: black;
            }
        }
    }
}

.swal2-confirm {
    background-color: $primary-color !important;
}
.eyecatcher {
    position: absolute;
    bottom: -15%;
    left: 1%;
    svg {
        fill: "#D5D5D5";
        position: absolute;
        transform: rotate(70deg);
        transition: "all 1s ease-in-out";
        z-index: 1;
        width: 150px;
        animation: zoom 10s infinite linear ease-in-out;
    }
    @keyframes zoom {
        0% {
            width: 140px;
        }
        50% {
            width: 150px;
        }
        100% {
            width: 140px;
        }
    }
    @media screen and (max-width: 900px) {
        display: none;
    }
}
/// BURGER MENU
.menu-wrap {
    background-color: #fff;
    width: 100vw;
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100px;
    position: fixed;
    z-index: 9999;
    top: 0;
    .logo_burger {
        position: absolute;
        z-index: 99;
        color: white;
        display: flex;
        flex-direction: column;
        left: 1em;
        top: 1em;
        text-align: center;
    }
    nav {
        background-color: $primary-color;
        position: fixed;
        top: 0;
        width: 53%;
        height: 100%;
        opacity: 0;
        visibility: hidden;
        transition: all 0.3s ease-in-out;
        z-index: -1;
        right: 0;
        top: 0em;

        ul {
            list-style-type: none;
            padding: 0;
            margin: 0;
            transition: all 0.3s ease-in-out;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin: 5em 2em;
            li {
                transform: translateY(50px);
                opacity: 0;

                a {
                    display: block;
                    text-decoration: none;
                    font-size: 25px;
                    text-align: right;
                    padding: 10px 0;
                    color: white;
                    font-weight: 600;
                    transition: all 0.2s ease-in-out;
                    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
                }
            }
        }
    }
    .toggle-btn {
        z-index: 1999;
        right: 20px;
        top: 20px;
        cursor: pointer;
        width: 50px;
        height: 100px;
        position: absolute;
        .bar {
            width: 40px;
            height: 5px;
            margin: 7px auto;
            background-color: $primary-color;
            transition: all 0.3s ease-in-out;
            // box-shadow: 0 0 3px 1px rgba(0, 0, 0, 0.301);
        }
    }
    .btn-toggle-btn {
        display: block;
        // position: fixed;
        z-index: 10;
        // right: 1em;
        // top: 1em;
        cursor: pointer;
        // z-index: 99999999999;
        .bar {
            width: 30px;
            height: 5px;
            margin: 7px auto;
            background-color: white;
            transition: all 0.3s ease-in-out;
            // box-shadow: 0 0 3px 1px rgba(212, 212, 212, 0.055);
            &:nth-child(2) {
                transform: translateX(50px);
                opacity: 0;
            }
            &:nth-child(1) {
                transform: translateY(16px) rotate(45deg);
            }
            &:nth-child(3) {
                transform: translateY(-8px) rotate(-45deg);
            }
        }
    }

    .active-nav {
        opacity: 1;
        visibility: visible;

        ul {
            top: 70px;

            li {
                transform: translateY(0px);
                opacity: 1;
                @for $i from 1 through 9 {
                    $t: $i * 0.1;
                    &:nth-child(#{$i}) {
                        transition: all 0.3s cubic-bezier(0.6, 0, 0.8, 1.5) #{$t}s;
                    }
                }
            }
        }
    }
    @media screen and (min-width: 991px) {
        display: none;
    }
}

/// loader
.loading_screen {
    height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    display: flex;
    text-align: center;
}
.lds-spinner {
    margin-top: 2%;
    color: $primary-color;
    width: 80px;
    height: 80px;
}
.lds-spinner div {
    transform-origin: 40px 40px;
    animation: lds-spinner 1.2s linear infinite;
}
.lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 3px;
    left: 37px;
    width: 6px;
    height: 18px;
    border-radius: 20%;
    background: $primary-color;
}
.lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
}
.lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
}
.lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
}
.lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
}
.lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
}
.lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
}
.lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
}
.lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
}
.lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
}
.lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
}
.lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
}
.lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
}
@keyframes lds-spinner {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.cookies-consent {
    position: fixed;
    bottom: 1em;
    left: 1em;
    background: rgba(0, 0, 0, 0.918);
    width: 400px;
    height: 150px;
    box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.301);
 
    .cookies-inner {

        display: flex;
        height: 100%;
        text-align: center;
        flex-direction: column;
        justify-content: center;
        align-items: center;
     
        span {
            color: white;
            // padding-top: 1em;
            a{
            
                text-decoration: none;
                color: $primary-color;
            }
        }
    }
}
